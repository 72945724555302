import { Spine } from 'pixi-spine';
import { Loader, Sprite, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../../config';
import { EventTypes, GameMode, UserBonus } from '../../../global.d';
import { setGameMode, setUserLastBonusBet } from '../../../gql/cache';
import { ResourceTypes } from '../../../resources.d';
import { isFreeSpinMode } from '../../../utils';
import { multiplierTextStyle } from '../../buyFeature/textStyles';
import { TextField } from '../../components/TextField';
import { ViewContainer } from '../../components/ViewContainer';
import { REELS_AMOUNT, REEL_WIDTH, SLOT_HEIGHT, SLOT_WIDTH, eventManager } from '../../config';

export class FreeSpinsReelFeature extends ViewContainer {
  private multiplierText: TextField;

  private multiplierValue = 0;

  private spine: Spine;

  constructor() {
    super();
    const sprite = new Sprite(Texture.from(ResourceTypes.reel5Background));
    this.addChild(sprite);
    const reelCover = new Spine(Loader.shared.resources['reelCover']!.spineData!);
    reelCover.state.setAnimation(0, 'cover_vfx_loop', true);
    reelCover.position.set(REEL_WIDTH / 2, SLOT_HEIGHT * 1.5);
    this.addChild(reelCover);
    this.spine = new Spine(Loader.shared.resources['collect_symbol']!.spineData!);
    this.addChild(this.spine);
    this.spine.state.setAnimation(0, 'collect_symbol_win_2', false);
    this.spine.skeleton.findSlot('multiplayer_collect').currentSprite.texture = Texture.EMPTY;
    this.multiplierText = new TextField('x0', SLOT_WIDTH, SLOT_HEIGHT, multiplierTextStyle);
    this.multiplierText.text.anchor.set(0.5, 0.5);
    this.spine.skeleton.findSlot('multiplayer_collect').currentSprite.addChild(this.multiplierText.getText());
    this.spine.position.set(REEL_WIDTH / 2 + 15, SLOT_HEIGHT * 1.5);
    this.spine.scale.set(1.5, 1.5);
    this.zIndex = 10000;
    this.x = REEL_WIDTH * (REELS_AMOUNT - 1);
    this.visible = false;
    eventManager.on(EventTypes.FREE_SPINS_COLLECTOR_ADD_VALUE, this.addMultiplierValue.bind(this));
    eventManager.on(EventTypes.FREE_SPINS_COLLECTOR_UPDATE_VALUE, this.updateMultiplierValue.bind(this));
    eventManager.on(EventTypes.MOVE_MULTIPLIER_START, this.moveMultiplier.bind(this));
  }

  public moveMultiplier(): void {
    this.multiplierText.text.visible = false;
    eventManager.emit(EventTypes.MOVE_MULTIPLIER_ANIMATION_START, this.multiplierText.text);
  }

  public updateMultiplierValue(value: number): void {
    this.multiplierValue = value;
    this.multiplierText.setText(`x${value}`);
  }

  public addMultiplierValue(value: number): void {
    AudioApi.play({
      type: ISongs.CollectWin,
      stopPrev: true,
    });
    this.multiplierValue = value;
    this.multiplierText.setText(`x${this.multiplierValue}`);
    this.spine.state.setAnimation(0, 'collect_symbol_win_2', false).listener = {
      complete(_entry) {
        eventManager.emit(EventTypes.END_COLLECT_FEATURE);
      },
    };
  }

  protected override onBrokenGame(_bonus: UserBonus): void {
    if (isFreeSpinMode(setGameMode())) {
      this.updateMultiplierValue(setUserLastBonusBet().data.features.moneyCollectFeature?.totalMoneyValue || 0);
    }
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.FREE_SPINS:
        this.visible = true;
        this.multiplierText.text.visible = true;
        break;
      default:
        this.visible = false;
    }
  }
}
