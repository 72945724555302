import i18n from 'i18next';
import _ from 'lodash';
import { Loader, LoaderResource } from 'pixi.js';

import type { ILoaderResource } from '@phoenix7dev/shared-components/dist/loader/d';
import type { IResource } from '@phoenix7dev/shared-components/dist/resources/d';
import { formatNumber as utilsFormatNumber } from '@phoenix7dev/utils-fe';

import variables from '../assets/styles/export.module.scss';
import { SlotId, config } from '../config';
import { EventTypes } from '../global.d';
import {
  setBetAmount,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setGameMode,
  setSlotConfig,
  setStressful,
} from '../gql/cache';
import { MAXIMUM_FRACTION_DIGITS, MINIMUM_FRACTION_DIGITS, eventManager } from '../slotMachine/config';
import type { Features } from '../slotMachine/d';

import { isFreeSpinMode } from './helper';

interface IPixiAssets {
  name: string;
  src: string;
}

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const pixiLoad = (): Promise<Partial<Record<string, LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    Loader.shared.load((_loader, resources) => {
      const failed = _.filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
    Loader.shared.onError.once(() => {
      return reject();
    });
  });
};

export const loadPixiAssets = (assets: IPixiAssets[], baseUrl: string): Promise<void> => {
  Loader.shared.baseUrl = baseUrl;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    assets.forEach((asset) => Loader.shared.add(asset.name, asset.src));
    let tries = config.failureRetries;
    let success = false;

    while (tries > 0) {
      try {
        tries -= 1;
        await pixiLoad();
        success = true;
        break;
      } catch (err) {
        console.error(err);
      }
    }

    return success ? resolve() : reject();
  });
};

export const loadImages = async (
  assets: IterableIterator<[string, IResource]>,
  cb?: CallableFunction,
): Promise<void> => {
  let promises: Promise<IResource>[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, value] of assets) {
    promises.push(
      new Promise((resolve, reject) => {
        const asset: HTMLImageElement = new Image();
        asset.src = value.source;
        asset.onload = () => {
          if (cb) cb(value.key);
          resolve(value);
        };
        asset.onerror = () => reject(value);
      }),
    );
  }

  let tries = config.failureRetries;
  let success = false;

  while (tries > 0) {
    try {
      tries -= 1;
      const result = await Promise.allSettled(promises);
      const failed = _.filter(
        result,
        (asset: { status: string }) => asset.status === 'rejected',
      ) as PromiseRejectedResult[];

      if (failed.length) {
        promises = failed.map((rejected) => {
          return new Promise((resolve, reject) => {
            const asset: HTMLImageElement = new Image();
            asset.src = (rejected.reason as { source: string; key: string }).source as string;
            asset.onload = () => {
              if (cb) cb((rejected.reason as { source: string; key: string }).key);
              resolve(rejected.reason);
            };
            asset.onerror = () => reject(rejected.reason);
          });
        });
        continue;
      }
      success = true;
      break;
    } catch (err) {
      console.error(err);
    }
  }

  return success ? Promise.resolve() : Promise.reject();
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const isTesting = (): boolean => {
  return window.location.host.includes('testing');
};

export const isMobilePortrait = (width: number, height: number): boolean => {
  const isPortrait = height >= width;
  const maxWidth = parseInt(variables['breakpointMobilePortraitMax'] as string, 10);

  return isPortrait && width <= maxWidth;
};

export const isBuyFeatureEnabled = (features: Features[] = []): boolean => {
  const freeSpinFeature = features.find((i) => i.id === 'freeSpins');

  return freeSpinFeature?.enabled || false;
};

export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (isMobilePortrait(width, height)) {
    return height * (parseInt(variables['bottomHeightPercentMobilePortrait'] as string, 10) / 100);
  }
  return height * (parseInt(variables['bottomHeightPercent'] as string, 10) / 100);
};

export const getFromMappedSymbol = <T>(map: Record<SlotId, { default: T; freespin?: T }>, id: SlotId): T =>
  isFreeSpinMode(setGameMode()) ? map[id as SlotId].freespin ?? map[id as SlotId].default : map[id as SlotId].default;

export const normalizeBalance = (balance = 0): number => {
  return balance / 100;
};

export const normalizeCoins = (coins = 0, coinValue = setCoinValue()): number => {
  return (coins * coinValue) / 100;
};

export const showCurrency = (currency: string): boolean => {
  return currency !== 'FUN';
};

export const formatNumber = (currency = 'FUN', value = 0, showCurrency = false): string => {
  return utilsFormatNumber({
    currency,
    value,
    showCurrency,
    minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
    maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
  });
};

export const loadErrorHandler = (error?: Error, resources?: ILoaderResource[]): void => {
  const stage = resources?.find((r) => !!r.error);
  const errorMsg = stage?.error as unknown as string;
  setStressful({
    show: true,
    type: 'network',
    message:
      (i18n.t([errorMsg === 'Failed to fetch' ? 'errors.UNKNOWN.NETWORK' : 'errors.UNKNOWN.UNKNOWN']) as string) ||
      (error as unknown as string),
  });
};

export const queryParams = new URLSearchParams(window.location.search);

export const findSubstituteCoinAmount = (requestedCoinAmount: number, coinAmounts: number[]): number => {
  for (let i = coinAmounts.length - 1; i >= 0; i--) {
    const coinAmount = coinAmounts[i]!;

    if (coinAmount <= requestedCoinAmount) {
      return coinAmount;
    }
  }

  return coinAmounts[0] ?? 0; // return 0 if BE send empty arr []
};

// updated coin value from BE after bonus game, because on bonus game we use Coin Value from history
export const updateCoinValueAfterBonuses = (): void => {
  const coinValue = setSlotConfig().clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
  const coinAmount = findSubstituteCoinAmount(setCoinAmount(), setSlotConfig().clientSettings.coinAmounts.default);
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setBetAmount(coinAmount * setSlotConfig().lineSets[0]!.coinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};
