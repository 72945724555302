import { Group, Layer } from '@pixi/layers';
import { Spine } from 'pixi-spine';
import { ITextStyle, Loader, Sprite, Spritesheet, Texture } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, MAPPED_SYMBOLS_WIN_ANIMATIONS, SlotId } from '../../../config';
import {
  EventTypes,
  GameMode,
  IMoneyBagFeature,
  ISettledBet,
  ISpinAndGrabFeature,
  ISpinAndGrabSlot,
  Payline,
} from '../../../global.d';
import {
  setBetAmount,
  setBetResult,
  setBottomContainerTotalWin,
  setCurrentBonus,
  setCurrentIsTurboSpin,
  setGrabAndSpinTotalWin,
  setIsAutoSpins,
} from '../../../gql/cache';
import { Logic } from '../../../logic';
import type { SpinAndGrabController } from '../../../logic/controllers/SpinAndGrabController';
import { getBetResult, nextTick } from '../../../utils';
import type Animation from '../../animations/animation';
import AnimationChain from '../../animations/animationChain';
import AnimationGroup from '../../animations/animationGroup';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import { multiplierTextStyle } from '../../buyFeature/textStyles';
import { TextField } from '../../components/TextField';
import { ViewContainer } from '../../components/ViewContainer';
import {
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOT_HEIGHT,
  SLOT_SCALE,
  SLOT_WIDTH,
  eventManager,
} from '../../config';
import type { Icon } from '../../d';

import type { WinSlotsContainer } from './winSlotsContainer';

const allWinIsDoubledTextSyle: Partial<ITextStyle> = {
  align: 'center',
  dropShadow: true,
  dropShadowAngle: 8,
  dropShadowColor: '#750000',
  dropShadowDistance: 3,
  fill: ['#ffc05c', '#ffae00', '#bd7400', '#bd7400', '#ad5400', '#e08a00'],
  fillGradientStops: [0.6],
  fontFamily: 'NotoSans-Bold',
  fontSize: 140,
  lineJoin: 'round',
  miterLimit: 65,
  stroke: '#4d1700',
  strokeThickness: 9,
  whiteSpace: 'normal',
};

export class BaseWinSlotsPresentation extends ViewContainer implements WinSlotsContainer {
  private reelSpinOrderMap: { [x: string]: number } = {
    '1': 3,
    '0': 0,
    '2': 6,
    '3': 9,
    '4': 12,
    '5': 1,
    '6': 4,
    '7': 7,
    '8': 10,
    '9': 13,
    '10': 2,
    '11': 5,
    '12': 8,
    '13': 11,
    '14': 14,
  };

  private allSlotsHighlight: AnimationChain | null = null;

  private animationsArr: Spine[] = [];

  private loopAnimation: Animation | null = null;

  private collectMultipliersValue!: TextField;

  public layer: Layer;

  public layersGroup: Group;

  private collectIsTriggered = false;

  private multipliers: Spritesheet;

  constructor() {
    super();
    this.layersGroup = new Group(1, (layer) => {
      layer.zOrder = layer.name === 'moneySpine' ? 100 : 200;
    });
    this.layer = new Layer(this.layersGroup);
    this.addChild(this.layer);
    this.multipliers = Loader.shared.resources['multipliersSprite']!.spritesheet!;
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.showWin.bind(this));
    eventManager.addListener(EventTypes.START_COLLECT_FEATURE, this.startCollectFeature.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_ANIMATION, this.skipWinSlotsAnimation.bind(this));
    eventManager.addListener(
      EventTypes.START_SPIN_AND_GRAB_COLLECT_FEATURE,
      this.startSpinAndGrabCollectFeature.bind(this),
    );
    eventManager.addListener(
      EventTypes.START_SPIN_AND_GRAB_FINAL_COLLECT_FEATURE,
      this.startSpinAndGrabFinalCollectFeature.bind(this),
    );
  }

  private startCollectFeature(feature: IMoneyBagFeature): void {
    this.collectIsTriggered = true;
    const bagPositions = feature.moneySymbols.reduce((acc: number[], current) => [...acc, current.position], []);
    const collectPosition = feature.collectSymbols[0]!.position;
    const multipliers: Sprite[] = [];
    const collectorSpine = new Spine(Loader.shared.resources['collect_symbol']!.spineData!);
    collectorSpine.skeleton.findSlot('multiplayer_collect').currentSprite.texture = Texture.EMPTY;
    const spines = feature.moneySymbols.reduce((acc: Spine[], symbol) => {
      const spine = new Spine(Loader.shared.resources['money_symbol']!.spineData!);
      const x = REEL_WIDTH / 2 + REEL_WIDTH * (symbol.position % REELS_AMOUNT);
      const y = SLOT_HEIGHT * Math.floor(symbol.position / REELS_AMOUNT) + SLOT_HEIGHT / 2;
      spine.name = 'moneySpine';
      spine.parentGroup = this.layersGroup;
      spine.position.set(x, y);
      const multiplier = new Sprite(this.multipliers.textures[symbol.value]);
      multiplier.anchor.set(0.5);
      multiplier.name = 'multiplierValue';
      multiplier.parentGroup = this.layersGroup;
      multiplier.position.set(x, y);
      multipliers.push(multiplier);
      spine.skeleton.findSlot('multiplayers_money').currentSprite.texture = Texture.EMPTY;
      this.addChild(multiplier);
      this.addChild(spine);
      return [...acc, spine];
    }, []);
    let counter = 0;
    spines.forEach((spine) => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, bagPositions, false);
      eventManager.emit(EventTypes.REMOVE_BAGS_MULTIPLIER, bagPositions);
      const removeCallback = () => this.removeChild(spine);
      spine.state.setAnimation(0, 'money_symbol_win', false).listener = {
        complete(_entry) {
          nextTick(removeCallback);
          counter += 1;
          if (counter === spines.length) {
            eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, bagPositions, true);
          }
        },
      };
    });
    const group = new AnimationGroup();
    const duration = 850;
    const collectorX = REEL_WIDTH / 2 + REEL_WIDTH * (collectPosition % REELS_AMOUNT);
    const collectorY = SLOT_HEIGHT * Math.floor(collectPosition / REELS_AMOUNT) + SLOT_HEIGHT / 2;
    collectorSpine.position.set(collectorX, collectorY);
    this.addChild(collectorSpine);
    multipliers.forEach((multiplier) => {
      const moveX: Tween = new Tween({
        object: multiplier,
        property: TweenProperties.X,
        propertyBeginValue: multiplier.x,
        target: collectorX,
        duration,
      });
      const moveY: Tween = new Tween({
        object: multiplier,
        property: TweenProperties.Y,
        propertyBeginValue: multiplier.y,
        target: collectorY,
        duration,
      });
      group.addAnimation(moveX);
      group.addAnimation(moveY);
    });
    group.addOnComplete(() => {
      multipliers.forEach((multiplier) => this.removeChild(multiplier));
      if (Logic.the.controller.gameMode === GameMode.FREE_SPINS) {
        eventManager.emit(EventTypes.FREE_SPINS_COLLECTOR_ADD_VALUE, feature.collectSymbols[0]!.value);
      } else {
        const collectMultiplier = new TextField(
          `x${feature.collectSymbols[0]!.value}`,
          SLOT_WIDTH,
          SLOT_HEIGHT,
          multiplierTextStyle,
        );
        collectMultiplier.getText().anchor.set(0.5);
        eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [collectPosition], false);
        collectorSpine.skeleton.findSlot('multiplayer_collect').currentSprite.addChild(collectMultiplier.getText());
        this.collectMultipliersValue = collectMultiplier;
        const removeCollectorSpineCallback = () => this.removeChild(collectorSpine);
        AudioApi.play({
          type: ISongs.CollectWin,
          stopPrev: true,
        });
        collectorSpine.state.setAnimation(0, 'collect_symbol_win', false).listener = {
          complete(_entry) {
            nextTick(removeCollectorSpineCallback);
            eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [collectPosition], true);
            eventManager.emit(EventTypes.END_COLLECT_FEATURE);
            eventManager.emit(EventTypes.SET_COLLECT_VALUE, feature.collectSymbols[0]!.value);
          },
        };
      }
    });
    group.start();
  }

  private startSpinAndGrabCollectFeature(initialCollectorSymbol?: ISpinAndGrabSlot): void {
    const { currentActiveSpinAndGrabRound } = Logic.the.controller as SpinAndGrabController;
    const initialMoneySymbols = (
      setCurrentBonus().data.spinAndGrabFeature as ISpinAndGrabFeature
    )?.startingCells.filter((cell) => cell.symbol === SlotId.M);
    const moneySymbolsFromRolledReels = initialCollectorSymbol
      ? []
      : (setCurrentBonus().data.spinAndGrabFeature as ISpinAndGrabFeature)?.rounds
          .slice(0, currentActiveSpinAndGrabRound)
          .map((round) => round.newCells)
          .reduce((acc, current) => [...acc, ...current], [])
          .filter((cell) => cell.symbol === SlotId.M);
    const allMoneySymbols = [...initialMoneySymbols, ...moneySymbolsFromRolledReels];
    const collectorSymbols = initialCollectorSymbol
      ? [initialCollectorSymbol]
      : (setCurrentBonus().data.spinAndGrabFeature as ISpinAndGrabFeature)?.rounds[
          currentActiveSpinAndGrabRound - 1
        ]!.newCells.filter((cell) => cell.symbol === SlotId.C);

    this.playCollectAnim(collectorSymbols, allMoneySymbols, 0);
  }

  private playCollectAnim(collectSymbols: ISpinAndGrabSlot[], moneySymbols: ISpinAndGrabSlot[], index: number): void {
    collectSymbols = collectSymbols.sort(
      (collect1, collect2) => this.reelSpinOrderMap[collect1.position]! - this.reelSpinOrderMap[collect2.position]!,
    );
    const group = new AnimationGroup();
    const duration = 1000;
    const collectAnimFn = this.playCollectAnim.bind(this);

    const collectorSpine = new Spine(Loader.shared.resources['collect_symbol']!.spineData!);
    const collectorX = REEL_WIDTH / 2 + REEL_WIDTH * (collectSymbols[index as number]!.position % REELS_AMOUNT);
    const collectorY =
      SLOT_HEIGHT * Math.floor(collectSymbols[index as number]!.position / REELS_AMOUNT) + SLOT_HEIGHT / 2;
    collectorSpine.skeleton.findSlot('multiplayer_collect').currentSprite.texture = Texture.EMPTY;
    collectorSpine.position.set(collectorX, collectorY);
    this.addChild(collectorSpine);

    let counter = 0;
    const multipliers: Sprite[] = [];
    const spines = moneySymbols.reduce((acc: Spine[], symbol) => {
      const spine = new Spine(Loader.shared.resources['money_symbol']!.spineData!);
      const x = REEL_WIDTH / 2 + REEL_WIDTH * (symbol.position % REELS_AMOUNT);
      const y = SLOT_HEIGHT * Math.floor(symbol.position / REELS_AMOUNT) + SLOT_HEIGHT / 2;
      spine.position.set(x, y);
      spine.name = 'moneySpine';
      spine.parentGroup = this.layersGroup;
      const multiplier = new Sprite(this.multipliers.textures[symbol.value!]);
      spine.skeleton.findSlot('multiplayers_money').currentSprite.texture = Texture.EMPTY;
      multiplier.anchor.set(0.5);
      multiplier.position.set(x, y);
      multipliers.push(multiplier);
      this.addChild(spine);
      this.addChild(multiplier);
      return [...acc, spine];
    }, []);

    spines.forEach((spine) => {
      eventManager.emit(EventTypes.SET_SPIN_AND_GRAB_SLOTS_VISIBILITY, moneySymbols, false);
      const removeCallback = () => this.removeChild(spine);
      spine.state.setAnimation(0, 'money_symbol_win', false).listener = {
        complete(_entry) {
          nextTick(removeCallback);
          counter += 1;
          if (counter === spines.length) {
            eventManager.emit(EventTypes.SET_SPIN_AND_GRAB_SLOTS_VISIBILITY, moneySymbols, true);
          }
        },
      };
    });
    multipliers.forEach((multiplier) => {
      const moveX: Tween = new Tween({
        object: multiplier,
        property: TweenProperties.X,
        propertyBeginValue: multiplier.x,
        target: collectorX,
        duration,
      });
      const moveY: Tween = new Tween({
        object: multiplier,
        property: TweenProperties.Y,
        propertyBeginValue: multiplier.y,
        target: collectorY,
        duration,
      });
      group.addAnimation(moveX);
      group.addAnimation(moveY);
    });

    group.addOnComplete(() => {
      multipliers.forEach((multiplier) => this.removeChild(multiplier));

      const collectMultiplier = new TextField(
        `x${collectSymbols[index as number]!.value}`,
        SLOT_WIDTH,
        SLOT_HEIGHT,
        multiplierTextStyle,
      );
      collectMultiplier.getText().anchor.set(0.5);

      eventManager.emit(EventTypes.SET_SPIN_AND_GRAB_SLOTS_VISIBILITY, [collectSymbols[index as number]], false);

      collectorSpine.skeleton.findSlot('multiplayer_collect').currentSprite.addChild(collectMultiplier.getText());

      const removeCollectorSpineCallback = () => this.removeChild(collectorSpine);

      AudioApi.play({
        type: ISongs.CollectWin,
        stopPrev: true,
      });
      collectorSpine.state.setAnimation(0, 'collect_symbol_win', false).listener = {
        complete(_entry) {
          nextTick(removeCollectorSpineCallback);
          eventManager.emit(EventTypes.SET_SPIN_AND_GRAB_SLOTS_VISIBILITY, [collectSymbols[index as number]], true);

          eventManager.emit(EventTypes.SET_SPIN_AND_GRAB_COLLECT_VALUE, collectSymbols[index as number]);

          if (index + 1 === collectSymbols.length) {
            eventManager.emit(EventTypes.END_SPIN_AND_GRAB_COLLECT_FEATURE);
          } else {
            collectAnimFn(collectSymbols, moneySymbols, index + 1);
          }
        },
      };
    });
    group.start();
  }

  private startSpinAndGrabFinalCollectFeature(_initialCollectorSymbol?: ISpinAndGrabSlot): void {
    let totalWin = setGrabAndSpinTotalWin();
    const initialSymbols = (setCurrentBonus().data.spinAndGrabFeature as ISpinAndGrabFeature)?.startingCells.filter(
      (cell) => cell.symbol !== SlotId.E,
    );
    const symbolsFromRolledReels = (setCurrentBonus().data.spinAndGrabFeature as ISpinAndGrabFeature)?.rounds
      .map((round) => round.newCells)
      .reduce((acc, current) => [...acc, ...current], [])
      .filter((cell) => cell.symbol !== SlotId.E);
    const allSymbols = [...initialSymbols, ...symbolsFromRolledReels].sort((s1, s2) => s1.position - s2.position);
    const isWinDoubled = allSymbols.length === 15; // @NOTE: 15 is number of reels in GrabAndSpinsReelsContainer
    const multipliers: {
      moneyItem: Sprite | null;
      collectItem: TextField | null;
    }[] = [];
    const spines = allSymbols.reduce((acc: Spine[], symbol) => {
      const isMoney = symbol.symbol === SlotId.M;
      const spineType = symbol.symbol === SlotId.M ? 'money_symbol' : 'collect_symbol';
      const spineSlotType = symbol.symbol === SlotId.M ? 'multiplayers_money' : 'multiplayer_collect';
      const spine = new Spine(Loader.shared.resources[spineType as string]!.spineData!);
      const emptyTextForAnimation = new TextField(``, SLOT_WIDTH, SLOT_HEIGHT);
      spine.skeleton.findSlot(spineSlotType).currentSprite.texture = Texture.EMPTY;
      spine.skeleton.findSlot(spineSlotType).currentSprite.addChild(emptyTextForAnimation.getText());
      const x = REEL_WIDTH / 2 + REEL_WIDTH * (symbol.position % REELS_AMOUNT);
      const y = SLOT_HEIGHT * Math.floor(symbol.position / REELS_AMOUNT) + SLOT_HEIGHT / 2;
      spine.position.set(x, y);
      spine.name = 'moneySpine';
      spine.parentGroup = this.layersGroup;
      spine.visible = false;
      if (!isMoney) {
        const multiplier = new TextField(`x${symbol.value}`, SLOT_WIDTH, SLOT_HEIGHT, multiplierTextStyle);
        multiplier.getText().name = 'multiplierValue';
        multiplier.getText().parentGroup = this.layersGroup;
        multiplier.text.position.set(x, y);
        multiplier.text.anchor.set(0.5);
        multipliers.push({
          collectItem: multiplier,
          moneyItem: null,
        });
        this.addChild(multiplier.getText());
      } else {
        const multiplier = new Sprite(this.multipliers.textures[symbol.value!]);
        multiplier.name = 'multiplierValue';
        multiplier.parentGroup = this.layersGroup;
        multiplier.position.set(x, y);
        multiplier.anchor.set(0.5);
        multipliers.push({
          collectItem: null,
          moneyItem: multiplier,
        });
        this.addChild(multiplier);
      }

      this.addChild(spine);
      return [...acc, spine];
    }, []);

    const chain = new AnimationChain();
    const duration = 1000;

    if (isWinDoubled) {
      const allWinsIsDoubledSpine = new Spine(Loader.shared.resources['popups']!.spineData!);
      const allWinsIsDoubledText = new TextField('ALL WINS\nX2 ', SLOT_WIDTH, SLOT_HEIGHT, allWinIsDoubledTextSyle);
      const container =
        allWinsIsDoubledSpine.slotContainers[allWinsIsDoubledSpine.skeleton.findSlotIndex('all_wins_x2')];
      allWinsIsDoubledText.getText().anchor.set(0.5);
      container!.removeChildren();
      container!.addChild(allWinsIsDoubledText.getText());
      const removeAllWinsIsDoubledSpineCallback = () => this.removeChild(allWinsIsDoubledSpine);

      allWinsIsDoubledSpine.x = SLOTS_CONTAINER_WIDTH / 2;
      allWinsIsDoubledSpine.y = SLOTS_CONTAINER_HEIGHT / 2;
      allWinsIsDoubledSpine.scale.y = -1;

      AudioApi.play({
        type: ISongs.SpinAndGrabDoubleUp,
        stopPrev: true,
      });
      allWinsIsDoubledSpine.state.setAnimation(0, 'all_wins_x2', false).listener = {
        complete(_entry) {
          nextTick(removeAllWinsIsDoubledSpineCallback);
        },
      };
      this.addChild(allWinsIsDoubledSpine);
    }

    multipliers.forEach((multiplier, i) => {
      const group = new AnimationGroup();
      const targetX = SLOT_WIDTH * 2.5; // @TODO: Not ideal solution, should be calculated as for Y
      const topToSafeAreaOffset = this.parent.parent.y;
      const baseWinSlotsOffset = this.position.y;
      const gameViewHeight = this.parent.height;
      const gameViewScaleY = this.parent.scale.y;
      const gameViewOffset = gameViewHeight / gameViewScaleY;
      const gameViewToBottomOffset = (window.innerHeight - topToSafeAreaOffset - gameViewHeight) / gameViewScaleY;
      const multiplierHeight = multiplier.collectItem
        ? multiplier.collectItem.getText().height
        : multiplier.moneyItem!.height / 4;
      const targetY = gameViewOffset + gameViewToBottomOffset - baseWinSlotsOffset - multiplierHeight;

      const moveX: Tween = new Tween({
        object: multiplier.collectItem ? multiplier.collectItem.text : multiplier.moneyItem!,
        property: TweenProperties.X,
        propertyBeginValue: multiplier.collectItem ? multiplier.collectItem.text.x : multiplier.moneyItem!.x,
        target: targetX,
        duration,
      });
      const moveY: Tween = new Tween({
        object: multiplier.collectItem ? multiplier.collectItem.text : multiplier.moneyItem!,
        property: TweenProperties.Y,
        propertyBeginValue: multiplier.collectItem ? multiplier.collectItem.text.y : multiplier.moneyItem!.y,
        target: targetY,
        duration,
      });

      group.addOnStart(() => {
        eventManager.emit(EventTypes.RESET_MULTIPLIER_ON_FINAL_COLLECT_FEATURE, allSymbols[i]);
        eventManager.emit(EventTypes.SET_SPIN_AND_GRAB_SLOTS_VISIBILITY, [allSymbols[i]], false);

        const removeCallback = () => this.removeChild(spines[i as number]!);
        const spineTypeWin = allSymbols[i as number]!.symbol === SlotId.M ? 'money_symbol_win' : 'collect_symbol_win';
        spines[i as number]!.visible = true;
        spines[i as number]!.state.setAnimation(0, spineTypeWin, false).listener = {
          complete(_entry) {
            nextTick(removeCallback);
            eventManager.emit(EventTypes.SET_SPIN_AND_GRAB_SLOTS_VISIBILITY, [allSymbols[i as number]], true);
          },
        };
      });
      group.addOnComplete(() => {
        AudioApi.play({
          type: ISongs.ValuesGathering,
          stopPrev: true,
        });
        const multiplierItem = multiplier.collectItem ? multiplier.collectItem.text : multiplier.moneyItem!;
        this.removeChild(multiplierItem);
        const win = setBetAmount() * (allSymbols[i as number]!.value as number) * (isWinDoubled ? 2 : 1);
        totalWin += win;
        setGrabAndSpinTotalWin(totalWin);
        setBottomContainerTotalWin(setBottomContainerTotalWin() + win);

        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
      });

      group.addAnimation(moveX);
      group.addAnimation(moveY);
      chain.appendAnimation(group);
    });
    chain.addOnComplete(() => {
      eventManager.emit(EventTypes.FINISH_SPIN_AND_GRAB);
    });
    chain.start();
  }

  private skipWinSlotsAnimation(): void {
    this.destroySpineWinAnimations();
    this.allSlotsHighlight?.skip();
    this.loopAnimation?.skip();
  }

  private destroySpineWinAnimations(): void {
    if (this.animationsArr.length) {
      this.animationsArr.forEach((spine) => {
        spine?.skeleton?.setToSetupPose();
        if (spine.state) {
          spine.state.tracks = [];
          nextTick(() => spine.destroy());
        }
      });
      this.animationsArr = [];
    }
  }

  public showWin(betResult: ISettledBet, paylinesData: Payline[]): void {
    this.animationsArr = [];
    const isFreeSpins = Logic.the.controller.gameMode === GameMode.FREE_SPINS;
    let paylines = paylinesData;
    const { spinResult } = betResult.bet.result;
    const currentSpinResult = [...spinResult];
    const isBonus = Boolean(betResult.bet.data.features.bonusData);
    if (isBonus) {
      const isSpinAndGrabFeature = Boolean(betResult.bet.data.features.bonusData.spinAndGrabFeature);

      if (isSpinAndGrabFeature) {
        const { startingCells } = betResult.bet.data.features.bonusData.spinAndGrabFeature;
        const moneyPaylines = startingCells.filter((item) => item.symbol === SlotId.M);
        paylines = [
          {
            amount: paylines[0]!.amount,
            lineId: paylines[0]!.lineId,
            winPositions: moneyPaylines.map((item) => item.position),
          },
        ];
      }
    }
    const paylinesPositions = new Set<number>();
    paylines.forEach((payline) => {
      payline.winPositions.forEach((position) => {
        paylinesPositions.add(position);
      });
    });
    const slots = Array.from(paylinesPositions).sort((a, b) => a - b);
    this.allSlotsHighlight = this.highlightSlots(slots, spinResult);
    const delayToStartNextAnimation = Tween.createDelayAnimation(setCurrentIsTurboSpin() ? 250 : 500);
    delayToStartNextAnimation.addOnComplete(() => {
      this.destroySpineWinAnimations();
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      eventManager.emit(EventTypes.WIN_LINE_ANIMATION_END);
      if (!isFreeSpins && !setIsAutoSpins()) this.loopAnimation?.start();
      if (isFreeSpins || setIsAutoSpins()) eventManager.emit(EventTypes.SHOW_TINT, false);
    });
    this.allSlotsHighlight.appendAnimation(delayToStartNextAnimation);
    this.allSlotsHighlight.addOnStart(() => {
      if (paylines.some((payline) => payline.lineId !== null)) {
        eventManager.emit(EventTypes.SHOW_TINT, true);
        eventManager.emit(EventTypes.SHOW_WIN_LINES, paylines);
      }
    });
    this.allSlotsHighlight.addOnSkip(() => {
      if (this.collectIsTriggered) this.collectIsTriggered = false;
      eventManager.emit(EventTypes.SHOW_TINT, false);
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      eventManager.emit(EventTypes.WIN_LINE_ANIMATION_END);
    });
    this.loopAnimation = this.createWinLineAnimation(currentSpinResult, paylines, true);

    this.loopAnimation.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
      if (this.collectIsTriggered) this.collectIsTriggered = false;
    });

    this.allSlotsHighlight.start();
  }

  public createSlotSpineAnimation(id: number, srcName: string, animationName: string): Animation {
    const dummy = Tween.createDelayAnimation(1333);

    dummy.addOnStart(() => {
      const animation = new Spine(Loader.shared.resources[srcName as string]!.spineData!);
      const x = REEL_WIDTH / 2 + REEL_WIDTH * (id % REELS_AMOUNT);
      const y = SLOT_HEIGHT * Math.floor(id / REELS_AMOUNT) + SLOT_HEIGHT / 2;
      animation.position.set(x, y);
      animation.scale.set(SLOT_SCALE);

      this.addChild(animation);
      if (animationName === 'collect_symbol_win') {
        animation.skeleton.findSlot('multiplayer_collect').currentSprite.texture = Texture.EMPTY;
        animation.skeleton
          .findSlot('multiplayer_collect')
          .currentSprite.addChild(this.collectMultipliersValue.getText());
      }
      if (animationName === 'money_symbol_win') {
        this.updateValueOnMoneySymbolInWinAnimation(animation, id);
      }

      animation.state.setAnimation(0, animationName, false);
      this.animationsArr.push(animation);
    });

    return dummy;
  }

  private updateValueOnMoneySymbolInWinAnimation(animation: Spine, id: number): void {
    const result = getBetResult(setBetResult());
    const { moneyCollectFeature } = result.bet.data.features;
    const spinAndGrabFeature = result.bet.data.features.bonusData
      ? result.bet.data.features.bonusData.spinAndGrabFeature
      : null;

    let multiplierValue = 0;

    if (moneyCollectFeature) {
      const moneySymbol = moneyCollectFeature.moneySymbols.filter((item) => item.position === id)[0];
      multiplierValue = moneySymbol!.value!;
    }
    if (spinAndGrabFeature) {
      const moneySymbol = spinAndGrabFeature.startingCells.filter((item) => item.position === id)[0];
      multiplierValue = moneySymbol!.value!;
    }

    const multiplier = new Sprite(this.multipliers.textures[multiplierValue]);
    const placeholder = animation.skeleton.findSlot('multiplayers_money').currentSprite;
    placeholder.texture = this.collectIsTriggered ? Texture.EMPTY : multiplier.texture;
  }

  public createWinLineAnimation(spinResult: Icon[], paylines: Payline[], isLoop: boolean): Animation {
    const isTurboSpin = setCurrentIsTurboSpin();
    const chain = new AnimationChain({ isLoop });
    paylines.forEach((payline) => {
      const animationGroup = new AnimationGroup();
      payline.winPositions.forEach((slotId) => {
        const symbolId = spinResult[slotId as number]!.id;
        animationGroup.addAnimation(
          this.createSlotSpineAnimation(
            slotId,
            MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId as SlotId].src!,
            MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId as SlotId].animation!,
          ),
        );
      });
      animationGroup.addOnStart(() => {
        eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, payline.winPositions, false);
        if (payline.lineId !== null) {
          eventManager.emit(EventTypes.SHOW_WIN_LINES, [payline]);
        }
      });
      animationGroup.addOnComplete(() => {
        eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, payline.winPositions, true);
      });
      animationGroup.addOnSkip(() => {
        eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, payline.winPositions, true);
      });
      const delayToStartNextAnimation = Tween.createDelayAnimation(isTurboSpin ? 250 : 500);
      delayToStartNextAnimation.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
        this.destroySpineWinAnimations();
      });
      delayToStartNextAnimation.addOnSkip(() => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
        this.destroySpineWinAnimations();
      });
      chain.appendAnimation(animationGroup);
      chain.appendAnimation(delayToStartNextAnimation);
    });
    return chain;
  }

  public highlightSlots(slots: number[], spinResult: Icon[]): AnimationChain {
    const chain = new AnimationChain();
    const animationGroup = new AnimationGroup({});
    slots.forEach((slotId, _index) => {
      const symbolId = spinResult[slotId as number]!.id;
      animationGroup.addAnimation(
        this.createSlotSpineAnimation(
          slotId,
          MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId as SlotId].src!,
          MAPPED_SYMBOLS_WIN_ANIMATIONS[symbolId as SlotId].animation!,
        ),
      );
    });
    animationGroup.addOnStart(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], false);
    });
    animationGroup.addOnComplete(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], true);
    });
    animationGroup.addOnSkip(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [...slots], true);
    });
    chain.appendAnimation(animationGroup);
    return chain;
  }
}
