import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { BonusStatus, EventTypes, GameMode, ISettledBet, UserBonus, bonusesId } from '../../global.d';
import {
  setBetAmount,
  setBetResult,
  setBottomContainerTotalWin,
  setBrokenGame,
  setCurrentBonus,
  setFreeRoundsBonus,
  setFreeRoundsTotalWin,
  setFreeSpinsTotalWin,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsSlotBusy,
  setIsTimeoutErrorMessage,
  setIsTransitionStarted,
  setLastRegularWinAmount,
  setLastSpinData,
  setReelSets,
  setReplayBet,
  setSlotConfig,
  setStressful,
} from '../../gql/cache';
import client from '../../gql/client';
import { getUserBonuses, slotBetGql } from '../../gql/query';
import i18n from '../../i18next';
import SlotMachine from '../../slotMachine';
import { PopupTypes, WinStages, eventManager } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { getBGMSoundByGameMode, getBetResult, getSpinResult, getWinStage, normalizeCoins } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { BaseController } from './BaseController';

export class FreeSpinController extends BaseController {
  public override gameMode: GameMode = GameMode.FREE_SPINS;

  public static override the = new FreeSpinController();

  protected constructor() {
    super();
  }

  public override enterIdleState(_prevState: States): void {
    setIsSlotBusy(false);
    if (setCurrentBonus().currentRound === setCurrentBonus().rounds) {
      if (setIsTimeoutErrorMessage()) return;
      const isFreeRoundsBonus = setFreeRoundsBonus().isActive;
      if (isFreeRoundsBonus) {
        setFreeRoundsTotalWin(setFreeSpinsTotalWin() + setFreeRoundsTotalWin());
        setBottomContainerTotalWin(setFreeRoundsTotalWin());
        // we check user bonuses to determine if frb is still active or not.
        client
          .query<{ userBonuses: UserBonus[] }>({
            query: getUserBonuses,
            variables: {
              input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
            },
            fetchPolicy: 'network-only',
          })
          .then((bonuses) => {
            const frbBonus = bonuses.data.userBonuses.find(
              (e) => e.bonusId === bonusesId[GameMode.FREE_ROUND_BONUS],
            ) as UserBonus;
            this.endFreeSpins(true, !!frbBonus);
          });
      } else {
        this.endFreeSpins(false, false);
      }
      return;
    }
    if (!setReplayBet()) {
      setTimeout(() => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND), 500);
    } else {
      if (setReplayBet() && setCurrentBonus().isActive) {
        setTimeout(() => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND), 500);
      } else {
        this.freeSpinsReplayFinished();
      }
    }
  }

  public override enterSpinState(_prevState: States): void {
    eventManager.emit(EventTypes.CLOSE_ALL_MULTIPLIER_EYES);
    eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    SlotMachine.the().spinSpinAnimation();
    setCurrentBonus({
      ...setCurrentBonus(),
      currentRound: setCurrentBonus().currentRound + 1,
    });
  }

  public override enterJingleState(_prevState: States): void {
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);
    if (setCurrentBonus().currentRound === setCurrentBonus().rounds) {
      setTimeout(() => {
        Logic.the.changeState(States.IDLE);
      }, 1000);
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }

  public override setResult(result: ISettledBet): void {
    const newResult = JSON.parse(JSON.stringify(result));
    newResult.bet.result.spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      setCurrentBonus().currentRound,
      setCurrentBonus().rounds,
    );
    setFreeSpinsTotalWin(setFreeSpinsTotalWin() + result.bet.result.winCoinAmount);
    setBottomContainerTotalWin(setBottomContainerTotalWin() + result.bet.result.winCoinAmount);
    setBetResult(newResult);
  }

  public override enterController(_prevGameMode: GameMode): void {
    if (setBrokenGame()) AudioApi.play({ type: ISongs.BGM_FS_Loop });
    setIsSlotBusy(false);
    eventManager.emit(EventTypes.IMMEDIATE_CLOSE_ALL_MULTIPLIER_EYES);
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      setCurrentBonus().currentRound,
      setCurrentBonus().rounds,
    );
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    if (setBottomContainerTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
    }
    if (setBrokenGame()) {
      Logic.the.changeState(States.IDLE);
      return;
    }

    if (setIsContinueAutoSpinsAfterFeature()) {
      AudioApi.play({ type: ISongs.BGM_FS_Loop });
      if (setIsDuringBigWinLoop()) {
        AudioApi.fadeOut(0, ISongs.BGM_FS_Loop);
      }
      Logic.the.changeState(States.IDLE);
    } else {
      eventManager.once(EventTypes.START_FREE_SPINS, () => {
        PopupController.the.closeCurrentPopup();
        Logic.the.changeState(States.IDLE);
      });
      PopupController.the.openPopup(PopupTypes.FREE_SPINS);
    }
  }

  public async getLastSpinData(): Promise<{
    reelPositions: number[];
    layout: SlotId[][];
  }> {
    const res = await client.query<{
      userBonuses: UserBonus[];
    }>({
      query: getUserBonuses,
      variables: { input: { id: setCurrentBonus().id } },
      fetchPolicy: 'network-only',
    });
    // todo replace with real backend logic
    const { betId } = res.data.userBonuses[0]!;
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: betId } },
      fetchPolicy: 'network-only',
    });
    const { reelPositions, reelSetId } = {
      reelPositions: bet.data.bet.result.reelPositions,
      winCountAmount: bet.data.bet.result.winCoinAmount,
      reelSetId: bet.data.bet.reelSetId,
    } as { reelPositions: number[]; winCountAmount: number; reelSetId: string };
    const layout = setReelSets().find((reelSet) => reelSet.id === reelSetId)?.layout;
    return { reelPositions, layout: layout || [] };
  }

  public override enterAfterWinState(_prevState: States): void {
    this.onEnterAfterWinState();
  }

  private onEnterAfterWinState(): void {
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    const { winCoinAmount } = getBetResult(setBetResult()).bet.result;
    setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
    const multiplier = normalizeCoins(winCoinAmount) / normalizeCoins(setBetAmount());
    if (multiplier > 7) {
      AudioApi.play({ type: ISongs.HighWin, stopPrev: true });
      return;
    }
    if (multiplier >= 5) {
      AudioApi.play({ type: ISongs.MediumWin, stopPrev: true });
      return;
    }
    if (multiplier >= 3) {
      AudioApi.play({ type: ISongs.SmallWin, stopPrev: true });
    }
  }

  public override enterWinLinePresentationState(_prevState: States): void {
    const betResult: ISettledBet = getBetResult(setBetResult());
    const paylines = betResult.paylines.filter((payline) => !payline.winPositions.includes(9));
    const { winCoinAmount } = betResult.bet.result;

    if (setCurrentBonus().currentRound === setCurrentBonus().rounds) {
      eventManager.once(EventTypes.MOVE_MULTIPLIER_END, () => {
        this.playWinAnimation(winCoinAmount);
        eventManager.emit(EventTypes.START_WIN_ANIMATION, betResult, paylines);
      });
      eventManager.emit(EventTypes.MOVE_MULTIPLIER_START);
    } else {
      this.playWinAnimation(winCoinAmount);

      eventManager.emit(EventTypes.START_WIN_ANIMATION, betResult, paylines);
    }
  }

  public override exitController(_nextGameMode: GameMode): void {
    eventManager.emit(EventTypes.FREE_SPINS_COLLECTOR_UPDATE_VALUE, 0);
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    AudioApi.stop({ type: ISongs.BGM_FS_Loop });
    if (setBrokenGame()) setBrokenGame(false);
    setCurrentBonus({ ...setCurrentBonus(), isActive: false });
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);
  }

  public endFreeSpins(wasActive: boolean, isActive: boolean): void {
    // you can override isActive to set true or false, to ignore query result.
    // isActive -> true , will continue the FRB bonus.
    setFreeRoundsBonus({ ...setFreeRoundsBonus(), isActive });
    AudioApi.fadeOut(1000, getBGMSoundByGameMode(this.gameMode));
    PopupController.the.openPopup(PopupTypes.FREE_SPINS_END);
    eventManager.once(EventTypes.END_FREE_SPINS, () => {
      this.getLastSpinData().then((res) => {
        PopupController.the.closeCurrentPopup();
        setLastSpinData(res);
        setIsTransitionStarted(true);
        Logic.the.changeState(States.TRANSITION);
        if (wasActive) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
          Logic.the.changeGameMode(GameMode.FREE_ROUND_BONUS, { bonus: setCurrentBonus(), endBonus: !isActive });
        } else {
          Logic.the.changeGameMode(GameMode.BASE_GAME);
        }
      });
    });
  }

  private playWinAnimation(winCoinAmount: number): void {
    if (getWinStage(winCoinAmount) >= WinStages.BigWin) {
      eventManager.once(EventTypes.COUNT_UP_END, () => {
        if (setBottomContainerTotalWin() > 0) {
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
        }
        Logic.the.changeState(States.AFTER_WIN);
      });
      eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount);
    } else {
      eventManager.once(EventTypes.WIN_LINE_ANIMATION_END, () => {
        if (setBottomContainerTotalWin() > 0) {
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
        }
        Logic.the.changeState(States.AFTER_WIN);
      });
      eventManager.emit(EventTypes.START_COUNT_UP, 0, winCoinAmount, 0);
    }
  }

  private freeSpinsReplayFinished(): void {
    setIsTimeoutErrorMessage(true);
    setStressful({
      show: true,
      type: 'none',
      message: i18n.t('replayBetMessage'),
    });
  }
}
